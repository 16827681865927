














import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScheduledReportsPopup from '@/modules/scheduled-reports';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/scheduled-reports/constants';
import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient, ICustomColumn,
} from '@/modules/scheduled-reports/interfaces';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import loop24 from '@/modules/common/filters/loop-24.filter';
import ClusterService, { ClusterServiceS } from '../cluster.service';
import ClusterRatesService, { ClusterRatesServiceS } from '../cluster-rates.service';

@Component({
    components: {
        ScheduledReportsPopup,
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(ClusterRatesServiceS) private clusterRatesService!: ClusterRatesService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    readonly dataType = DATA_TYPE.RATES_CLUSTER;

    get properties(): IProperties {
        return {
            dataType: this.dataType,
            dateRange: {
                options: [30],
                value: 30,
            },
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns(): ICustomColumn[] {
        return [{
            name: 'change',
            label: 'Change',
            value: true,
        }, {
            name: 'score',
            label: 'Score',
            value: true,
        }, {
            name: 'diff_delta',
            label: 'Diff #',
            value: true,
        }, {
            name: 'diff_precent',
            label: 'Diff %',
            value: true,
        }];
    }

    get filters(): IFilterItem[] {
        const filters = [] as IFilterItem[];

        if (this.clusterService.isLoadingMore) {
            return filters;
        }

        if (this.clusterService.currentProvider) {
            filters.push({
                name: 'providers',
                label: 'Source',
                value: this.clusterService.currentProvider,
                options: this.clusterRatesService.providers.map(p => ({
                    name: this.providersService.getProviderLabel(p),
                    value: p,
                })),
                disableOnEdit: false,
            });
        }

        return filters;
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '0',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }
}
